// createlocaleTextGetter function  from object {lang: 'value', lang2: 'value2'} who return a value for the current language

export function createLocaleTextGetter (locale) {
    return (obj) => {
        if (obj && locale) {
        const lang = locale.split('-')[0];
        return obj[lang] || obj.fr || obj.en;
        }
        return '';
    };
}
