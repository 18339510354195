import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { createLocaleTextGetter } from "../utils";
import parallaxAnimation from "../utils/parallaxAnimation";

import gsap from "gsap";
import imagesLoaded from "imagesloaded";

import ContactImageDesk from "../images/pages/contact/contact_desk.jpg";
import MapImageDesk from "../images/pages/contact/map_desk.jpg";

import "../styles/pages/contact.scss";

const ContactPage = ({ data, pageContext }) => {
  const localeTextGetter = createLocaleTextGetter(pageContext.locale);
  let splittedData = [];
  React.useEffect(() => {
    const textAnimation = require("../utils/textAnimation.js").default;
    if (window.innerWidth > 769) {
      let tl = gsap.timeline({});
      tl.set("main", { autoAlpha: 0 });
      imagesLoaded(".bi_contact_header", () => {
        splittedData = textAnimation.splitAllText();
  
        tl.set("main", { autoAlpha: 1 });
        tl.pause();
        setTimeout(() => {
          tl.play();
        }, 500);
        textAnimation.splitTitleApparition(
          splittedData.splittedTitles.find((el) => el.id === "h1").chars,
          tl
        );
        tl.fromTo(
          ".bi_contact_item",
          { y: 100, autoAlpha: 0 },
          {
            y: 0,
            autoAlpha: 1,
            stagger: 0.2,
            duration: 1,
            ease: "power2.inOut",
          },
          "-=0.5"
        );
        tl.fromTo(
          ".bi_contact_content picture",
          { autoAlpha: 0, y: 100, x: 100 },
          {
            autoAlpha: 1,
            y: 0,
            x: 0,
            duration: 1,
            ease: "power2.inOut",
            stagger: 0.2,
          },
          "<"
        );
      });
      parallaxAnimation.imagesParallax();
      parallaxAnimation.blockParallax();
    }
  }, []);

  const getCursorPositionInDiv = (e) => {
    const div = document.querySelector(".bi_contact_localisation");
    const rect = div.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    return { x, y };
  };

  const moveImage = (e) => {
    if (window.innerWidth > 769) {
      const image = document.querySelector(".bi_contact_localisation picture");
      const div = document.querySelector(".bi_contact_localisation");
      const rect = div.getBoundingClientRect();

      const horizontalCheckingCursorWithCenter = (e) => {
        let x = getCursorPositionInDiv(e).x;
        let centerX = rect.width / 2;
        let diffX = x - centerX;

        return diffX;
      };

      const verticalCheckingCursorWithCenter = (e) => {
        let y = getCursorPositionInDiv(e).y;
        let centerY = rect.height / 2;
        let diffY = y - centerY;

        return diffY;
      };

      gsap.set(image, {
        x: horizontalCheckingCursorWithCenter(e) / 12,
        y: verticalCheckingCursorWithCenter(e) / 12,
      });
    }
  };

  return (
    <main className="bi_contact">
      <Helmet htmlAttributes={{ lang: pageContext.locale }}>
        <title>
          {pageContext.locale === "fr"
            ? "Contact | Les Suites de l'île Barbe"
            : "Contact | The Suites of Île Barbe"}
        </title>
        <meta
          type="description"
          content={
            pageContext.locale === "fr" ? "Contactez nous" : "Contact us"
          }
        />
      </Helmet>
      <div className="bi_contact_header">
        <h1 data-splitted-title data-s-tag="h1">
          {pageContext.locale === "fr" ? "Contactez-nous" : "Contact us"}
        </h1>
      </div>
      <div className="bi_contact_content">
        <div className="bi_contact_content_text">
          <div className="bi_contact_item">
            <h2> {pageContext.locale === "fr" ? "Adresse" : "Address"}</h2>
            <p>
              Les Suites de l’Île Barbe <br /> 9 impasse Saint Loup, Île Barbe{" "}
              <br /> 69009 Lyon, France
            </p>
          </div>
          <div className="bi_contact_item">
            <h2>Contact</h2>
            <p>
              Tel. +33 (0) 4 69 96 38 33 <br />
              contact@ilebarbe.com
            </p>
          </div>
          <div className="bi_contact_item">
            <a
              href="https://www.facebook.com/lessuitesdelilebarbe"
              target="_blank"
            >
              <svg
                width="37"
                height="37"
                viewBox="0 0 37 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  className="circle"
                  cx="18.8057"
                  cy="18.5"
                  r="18.25"
                  fill="#D7D7D7"
                />
                <circle
                  cx="18.5"
                  cy="18.5"
                  r="18.25"
                  stroke="#D7D7D7"
                  stroke-width="0.5"
                />
                <path
                  d="M24.6666 10.9629H12.3333C11.5761 10.9629 10.9629 11.5761 10.9629 12.3333V24.6666C10.9629 25.4237 11.5761 26.037 12.3333 26.037H19.1851V19.8703H17.1296V17.8147H19.1851V16.7109C19.1851 14.6211 20.2033 13.7036 21.9402 13.7036C22.7721 13.7036 23.2119 13.7653 23.4202 13.7934V15.7592H22.2356C21.4983 15.7592 21.2407 16.1484 21.2407 16.9363V17.8147H23.4017L23.1085 19.8703H21.2407V26.037H24.6666C25.4237 26.037 26.037 25.4237 26.037 24.6666V12.3333C26.037 11.5761 25.423 10.9629 24.6666 10.9629Z"
                  fill="#3A3636"
                />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/lessuitesdelilebarbe/"
              target="_blank"
            >
            <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle
                  className="circle"
                  cx="18.8057"
                  cy="18.5"
                  r="18.25"
                  fill="#D7D7D7"
                />
                <circle
                  cx="18.5"
                  cy="18.5"
                  r="18.25"
                  stroke="#D7D7D7"
                  stroke-width="0.5"
                />
<path d="M24.8258 12.0946H13.3678C12.6644 12.0946 12.0947 12.6643 12.0947 13.3677V24.8257C12.0947 25.5291 12.6644 26.0988 13.3678 26.0988H24.8258C25.5292 26.0988 26.0989 25.5291 26.0989 24.8257V13.3677C26.0989 12.6643 25.5292 12.0946 24.8258 12.0946ZM16.5213 23.5526H14.6435V17.5104H16.5213V23.5526ZM15.5633 16.6466C14.958 16.6466 14.4684 16.1558 14.4684 15.5517C14.4684 14.9476 14.9586 14.4575 15.5633 14.4575C16.1668 14.4575 16.6576 14.9482 16.6576 15.5517C16.6576 16.1558 16.1668 16.6466 15.5633 16.6466ZM23.5553 23.5526H21.6787V20.6142C21.6787 19.9134 21.666 19.012 20.7029 19.012C19.7257 19.012 19.5755 19.7752 19.5755 20.5633V23.5526H17.699V17.5104H19.5004V18.336H19.5259C19.7767 17.8611 20.389 17.3602 21.3025 17.3602C23.2039 17.3602 23.5553 18.6116 23.5553 20.2387V23.5526V23.5526Z" fill="#3A3636"/>
</svg>

            </a>
            <a
              href="https://www.instagram.com/lessuitesdelilebarbe/"
              target="_blank"
            >
              <svg
                width="38"
                height="37"
                viewBox="0 0 38 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  className="circle"
                  cx="18.8057"
                  cy="18.5"
                  r="18.25"
                  fill="#D7D7D7"
                />
                <circle
                  cx="18.8057"
                  cy="18.5"
                  r="18.25"
                  stroke="#D7D7D7"
                  stroke-width="0.5"
                />
                <path
                  d="M15.3791 10.7918C13.0178 10.7918 11.0967 12.7129 11.0967 15.0742V21.926C11.0967 24.2874 13.0178 26.2084 15.3791 26.2084H22.2309C24.5923 26.2084 26.5133 24.2874 26.5133 21.926V15.0742C26.5133 12.7129 24.5923 10.7918 22.2309 10.7918H15.3791ZM15.3791 12.5047H22.2309C23.6476 12.5047 24.8004 13.6576 24.8004 15.0742V21.926C24.8004 23.3427 23.6476 24.4955 22.2309 24.4955H15.3791C13.9625 24.4955 12.8096 23.3427 12.8096 21.926V15.0742C12.8096 13.6576 13.9625 12.5047 15.3791 12.5047ZM23.0874 13.3612C22.8603 13.3612 22.6424 13.4515 22.4818 13.6121C22.3212 13.7727 22.2309 13.9906 22.2309 14.2177C22.2309 14.4449 22.3212 14.6627 22.4818 14.8233C22.6424 14.9839 22.8603 15.0742 23.0874 15.0742C23.3146 15.0742 23.5324 14.9839 23.693 14.8233C23.8537 14.6627 23.9439 14.4449 23.9439 14.2177C23.9439 13.9906 23.8537 13.7727 23.693 13.6121C23.5324 13.4515 23.3146 13.3612 23.0874 13.3612ZM18.805 14.2177C16.4437 14.2177 14.5226 16.1388 14.5226 18.5001C14.5226 20.8614 16.4437 22.7825 18.805 22.7825C21.1663 22.7825 23.0874 20.8614 23.0874 18.5001C23.0874 16.1388 21.1663 14.2177 18.805 14.2177ZM18.805 15.9307C20.2216 15.9307 21.3745 17.0835 21.3745 18.5001C21.3745 19.9167 20.2216 21.0696 18.805 21.0696C17.3884 21.0696 16.2356 19.9167 16.2356 18.5001C16.2356 17.0835 17.3884 15.9307 18.805 15.9307Z"
                  fill="#3A3636"
                />
              </svg>
            </a>
          </div>
        </div>
        <picture
          data-parallax="true"
          data-p-direction="bottom"
          data-p-vitesse="0.5"
        >
          <img src={ContactImageDesk} alt="" />
        </picture>
      </div>
      <div
        className="bi_contact_localisation"
        onMouseMove={(e) => {
          moveImage(e);
        }}
        onMouseLeave={(e) => {
          gsap.to(document.querySelector(".bi_contact_localisation svg"), {
            transform: "initial",
            duration: 0.3,
          });
        }}
      >
        <p>
          <a
            href="https://www.google.com/maps/place/Les+Suites+de+l'Île+Barbe/@45.7979513,4.8334287,17z/data=!4m8!3m7!1s0x47f4eb508628d491:0x96426d7d30ed8e86!5m2!4m1!1i2!8m2!3d45.7980435!4d4.8339259"
            target="_blank"
          >
            45.79814 N 4.83392 E
          </a>
        </p>
        <picture>
          <img src={MapImageDesk} alt="Image de la carte" />
        </picture>
      </div>
    </main>
  );
};

export default ContactPage;
