import gsap from 'gsap';

const imagesParallax = () => {
    // Select all picture elements
    const pictures = document.querySelectorAll("picture");
    // Loop through each picture element
    pictures.forEach((picture) => {
      // Get all data attributes
      const dataAttributes = picture.dataset;
      if (
        dataAttributes.hasOwnProperty("parallax") &&
        dataAttributes.parallax === "true"
      ) {
        gsap.fromTo(
          picture.querySelector("img"),
          {
            scale: 1.2,
            y:
              parseFloat(dataAttributes.pVitesse) *
              (dataAttributes.pDirection === "bottom" ? -100 : 100),
          },
          {
            scale: 1.2,
            y:
              parseFloat(dataAttributes.pVitesse) *
              (dataAttributes.pDirection === "bottom" ? 100 : -100),
            scrollTrigger: {
              trigger: picture,
              start: "top bottom",
              end: "bottom top",
              scrub: true,
            },
          }
        );
      }
    });
  };

  const blockParallax = () => {
    // select all elements with data-parallax attribute
    const elements = document.querySelectorAll("[data-parallax]");
    // remove all picture elements from the array
    elements.forEach((element) => {
      if (element.tagName === "PICTURE") {
      } else {
        const dataAttributes = element.dataset;
        gsap.fromTo(
          element,
          {
            y:
              parseFloat(dataAttributes.pVitesse) *
              (dataAttributes.pDirection === "bottom"
                ? offsetValue(element) + -100
                : offsetValue(element) + 100),
          },
          {
            y:
              parseFloat(dataAttributes.pVitesse) *
              (dataAttributes.pDirection === "bottom"
                ? offsetValue(element) + 100
                : offsetValue(element) + -100),
            scrollTrigger: {
              trigger: element,
              start: "top bottom",
              end: "bottom top",
              scrub: true,
            },
          }
        );
      }
    });
  };

  const offsetValue = (el) => {
    let value = gsap.getProperty(el, "y") ? gsap.getProperty(el, "y") : 0;
    return value;
  };

  export default {
    imagesParallax,
    blockParallax,
    offsetValue,
  }